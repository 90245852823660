import 'bootstrap/dist/css/bootstrap.min.css';
import particlesJS from 'particles.js'
import 'particles.js/particles'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './src/styles/global.css';
import './src/components/layout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    require('bootstrap/dist/js/bootstrap.bundle.min');
    require("@fortawesome/fontawesome-svg-core/styles.css");
    require("@fortawesome/fontawesome-svg-core");
  }
};

