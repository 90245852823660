module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rofhiwa Tshivhenga Portfolio","short_name":"Rofhiwa","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/free-r.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5fa414b1a7f0a40d43385b5e83528bc3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-MMP8Q2Y77S","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":100,"siteSpeedSampleRate":10,"cookieDomain":"rofhiwatshivhenga.com","exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
